:global(body) {
  padding: 0;
  margin: 0;
}

:global(a) {
    color: #2775bb
}

.newsletterIcon img {
  max-height: 4rem;
}